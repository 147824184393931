<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {};
</script>
<!-- -->
<style lang="scss">
.fx_center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
body {
  margin: 0px;
}
.flex-warp{
  display: flex;
  flex-wrap: wrap;
}
.elTabs {
  position: relative;
}
.elTabs_iput {
  position: absolute;
  top: 0;
  right: 0;
}
.reserveASeat {
  width: 100%;
  height: 96px;
}
.reserveASeatTow{
  width: 100%;
  height: 90px;
}
.centerWidth {
  width: 100%;
  height: 100vh;
}
.mr {
  margin-right: 15px;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  height: 95px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .imgicon {
    width: 150px;
    margin-left: -20px;
  }
  .headPortrait {
    background: #ced2d7;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
  }
}
.min_width {
  width: 100%;
  min-width: 1300px; /* 设置宽度为300px */
}
.max_width {
  width: 100%;
}
.headerWidthone {
  width: 92vw;
  margin: auto;
}
.headerWidthtow {
  width: 1300px;
  padding-left: 30px;
  margin: auto;
}
.fex {
  display: flex;
  align-items: center;
}
.curriculum {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  .curriculum_item {
    width: 317px;
    height: 250px;
    margin: 0px 20px 20px 0px;
    border-radius: 6px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    font-size: 14px;
    .curriculum_item_img {
      width: 100%;
      height: 158px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
    .typecenter {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 12px;
      width: 62px;
      height: 21px;
      line-height: 21px;
      background: rgba(64, 64, 64, 0.6);
      border-radius: 4px;
      text-align: center;
      color: rgba(255, 255, 255, 1);
    }

    .curriculum_item_lin {
      box-sizing: border-box;
      padding: 5px 15px;
      color: rgba(51, 51, 51, 1);
    }
  }
}
.curriculum .curriculum_item:nth-of-type(3n + 0) {
  margin: 0px 0px 18px 0px;
}
.img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ced2d7;
}
.mt {
  margin-top: 5px;
}
.mt10{
  margin-top: 10px;
}
.titlesize {
  font-weight: 700px;
  font-size: 18px;
  color: rgba(51, 51, 51, 1);
  margin-bottom: 10px;
}
.mb10 {
  margin-bottom: 10px;
}
.tabliat {
  min-width: 85px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  padding: 0px 10px;
  box-sizing: border-box;
}
.tabliatactive {
  cursor: pointer;
  color: rgba(212, 48, 48, 1);
}

.notitle {
  color: #808080;
  font-size: 14px;
  text-align: center;
  margin-top: 20px;
}
::v-deep.el-input--small .el-input__inner {
  border-radius: 20px;
}
.widthcenter {
  width: 1300px;
  margin: auto;
}
.elempty {
  margin: auto;
  margin-top: 100px;
}
.login {
  width: 55px;
  padding: 2px 10px;
  box-sizing: border-box;
  border-radius: 20px;
  border: 2px solid rgba(255, 255, 255, 1);
  font-size: 14px;
  margin-left: 15px;
  cursor: pointer;
  color:rgba(255, 255, 255, 1);
}
.fenye {
    margin: 30px 0px 40px 0px;
    float: right;
  }
  // 
  
.Sample {
  margin-top: 10px;
  .Sample_list {
    width: 240px;
    margin-right: 22px;
    margin-bottom: 20px;
    color: rgba(51, 51, 51, 1);
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    .Sample_title {
      width: 100%;
      height: 35px;
      opacity: 1;
      background: rgba(255, 255, 255, 0.7);
      line-height: 35px;
      text-align: center;
      font-size: 16px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      overflow: hidden; /*内容超出后隐藏*/
      text-overflow: ellipsis; /*超出内容显示为省略号*/
      white-space: nowrap; /*文本不进行换行*/
    }
    .elImage {
      width: 100%;
      height: 240px; /* 高度自动调整以保持比例 */
      border-radius: 8px;
    }
    .Sample_list_text {
      width: 100%;
      padding: 0px 5px;
      line-height: 25px;
      overflow: hidden; /*内容超出后隐藏*/
      text-overflow: ellipsis; /*超出内容显示为省略号*/
      white-space: nowrap; /*文本不进行换行*/
    }
  }
  .Sample_list:nth-of-type(5n + 0) {
    margin-right: 0;
  }
}
.mt20{
  margin-top: 20px;
}
.mb20{
  margin-bottom: 20px;
}
.mb5{
  margin-bottom: 10px;
}
.ml15{
  margin-left: 15px;
}
.applyFortitle {
  width: 100%;
  text-align: center;
  line-height: 60px;
}
.fontsize {
  font-size: 36px;
  font-weight: 500;
}
.from_btn {
  display: flex;
  justify-content: flex-end;
  margin-right: 8%;
  margin-top: 50px;
}
// 审批
.Approver {
  padding: 0px 15px;
  box-sizing: border-box;
  width: 100%;
  height: 110px;
  border-bottom: 2px solid rgba(245, 245, 245, 1);
  font-size: 14px;
}
.triangle-right {
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid rgba(229, 229, 229, 1);
}
.departmentcolor{
  font-size: 13px;
  margin-top: 3px;
  color: rgba(153, 153, 153, 1);
}
.mr5{
  margin-left: 10px;
}
.color1{
  color: rgba(235, 0, 0, 1);
}
.color2{
  color: rgba(67, 207, 124, 1);
}
.color3{
  color: rgba(255, 141, 26, 1);
}
.mr30{
  margin-right: 30px;
}
</style>